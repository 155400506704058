import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAccount from "../../hooks/useAccount.jsx";
import MainSidebar from "../MainSidebar/index.jsx";
import AddAssets from "../ModalForm/AddAsset/AddAssets.jsx";
import weburls from "../Weburls/weburls.jsx";
import { formatNumberWithCommas, parseAssetOrLiabilityData } from "../../utilities/helper.jsx";
import InfoCard from "../InfoCard/index.jsx";
import EditLiabilityOrAsset from "../ModalForm/AddLiability/EditLiabilityOrAsset.jsx";
import PageTitle from "../PageTitle/index.jsx";
import UserImageInfoToggle from "../UserImageInfoToggle/index.jsx";
import { moduleInfoPopUpData } from "../../utilities/Statics/index.jsx";

const NetWorth = () => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const { accountData, error } = useAccount();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [liabilityData, seLiabilityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [netWorth, setNetWorth] = useState();
  const [modals, setModals] = useState({
    addLiability: false,
    addAsset: false,
    editAsset: false,
    editLiability: false,
    delete: false,
  });
  const [DataForUpdating, setDataForUpdating] = useState({});
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const allLiabilityTotal =
    liabilityData?.length > 0
      ? liabilityData.reduce((acc, liability) => acc + liability.value, 0)
      : 0;
  const allAssetTotal =
    assetData?.length > 0
      ? assetData.reduce((acc, asset) => acc + asset.value, 0)
      : 0;
  const { uuid } = useParams();

  const openModal = (modal) => setModals({ ...modals, [modal]: true });
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const handleEditAssetLiability = (data, elementType) => {
    navigate(`/NetWorth/details/${uuid}`, { state: {...data,elementType} });
  };

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Assets}${uuid}`);
      if (response?.data?.assets?.length > 0) {
        const parsedAssets = parseAssetOrLiabilityData(response?.data?.assets);
        setAssetData(parsedAssets);
        localStorage.setItem(
          "assetsId",
          JSON.stringify(response?.data?.assets[0]?.assetsId)
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  }, [setAssetData, setLoading, uuid]);

  const fetchLiability = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Liability}${uuid}`);
      if (response?.data?.liability?.length > 0) {
        const parsedAssets = parseAssetOrLiabilityData(
          response?.data?.liability
        );
        seLiabilityData(parsedAssets);
      }
    } catch (error) {
      setLoading(false);
      console.error("error", error);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [seLiabilityData, setLoading, uuid]);

  const fetchNetWorth = useCallback(async () => {
    try {
      const response = await axios.get(
        `${weburls.Get_NetWorth_Progress}${uuid}`
      );
      setNetWorth(response.data);
    } catch (error) {
      console.error("error", error);
    }
  }, [setNetWorth, uuid]);

  useEffect(() => {
    fetchAssets();
    fetchLiability();
    fetchNetWorth();
  }, [fetchAssets, fetchLiability, fetchNetWorth]);

  function calculatePercentage(asset) {
    return (asset?.value / allAssetTotal) * 100;
  }

  const Amount = ({ amount }) => {
    const isNegative = amount < 0;
    const absoluteAmount = Math.abs(amount);
    const formattedAmount = formatNumberWithCommas(absoluteAmount);
    const displayAmount = isNegative ? `-$${formattedAmount}` : `$${formattedAmount}`;
    return (
      <span>
        {displayAmount} 
      </span>
    );
  };
  

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer net-worth-main">
              <div className="row">
                <PageTitle
                  title={"Your Net Worth"}
                  description={
                    "See what you own & what your owe to know your net worth."
                  }
                />
                <UserImageInfoToggle
                  isPopupOpenInfo={isPopupOpenInfo}
                  togglePopupInfo={togglePopupInfo}
                  title={moduleInfoPopUpData.netWorth.title}
                  description={moduleInfoPopUpData.netWorth.description}
                />
              </div>
              <div className="row mt-5">
                <div className="col-md-12 col-lg-8 left-section total-bal-main-first-wrp">
                  <div className="total-balance-wrp">
                    <div className="balance-wrp-heading">
                      <h4 className="med-head">Current Net Worth</h4>
                      <h2 className="big-head">
                      <Amount amount={allAssetTotal - allLiabilityTotal}/>
                      </h2>
                      <div className="row mt-3 balance-wrp-sub-heading">
                      <div className="text-white med-new-font">
                        Assets:{" "}
                        <span className="new-green">
                        <Amount amount={allAssetTotal}/>
                        </span>
                         &nbsp;| Liabilities:
                        <span className="new-light-red">
                        <Amount amount={allLiabilityTotal}/>
                        </span>
                      </div>
                      <div className="col-md-7 img-sec"></div>
                      <div className="col-md-5 right mt-5 full-breakdown-arrow">
                      </div>
                      <div className="col-md-7 img-sec"></div>
                      <div className="col-md-5 right mt-5 full-breakdown-arrow">
                      </div>
                    </div>
                    </div>
                  
                  </div>
                </div>
                <div className="net-worth-line-cart-canvas col-lg-4 col-md-12 d-flex flex-column gap-2">
                  <div className="asset-tile">
                    <div className="d-flex flex-column align-items-center w-full">
                      <span className="total-asset">Total assets</span>
                      <span className="asset-amount">
                      <Amount amount={allAssetTotal}/>
                      </span>
                    </div>
                  </div>
                  <div className="asset-tile asset-tile-red">
                    <div className="d-flex flex-column align-items-center w-full">
                      <span className="total-asset">Total Liabilities</span>
                      <span className="asset-amount">
                      <Amount amount={allLiabilityTotal}/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="asset-listing-flex">
                <div className="col-lg-6">
                  <div className="mt-5 assets-wrp">
                    <h5>Your assets </h5>
                    <div className="text-14px mb-4">
                      These are the things you own.
                    </div>
                    <div className="merchants-inner-wrp "></div>
                    {assetData?.map((ele, index) => (
                      <div
                        className="liability-card mt-3"
                        key={index}
                        onClick={() => handleEditAssetLiability(ele, "editAsset")}
                      >
                        <InfoCard
                          amount={ele?.amount}
                          avatar={ele?.emoji}
                          name={ele?.name}
                          isEmoji={true}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="profile-dashboard mt-3 ms-0 add-asset-btn-first">
                    <Link to={`/NetWorth/details/${uuid}`} state={{ elementType: 'asset' }}>
                      <button
                        className="comn-btn"
                        // onClick={() => openModal("addAsset")}
                      >
                        Add asset
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 liability-section">
                  <div className="big-margin liability-wrp">
                    <h5>Your liabilities </h5>
                    <div className="text-14px mb-4">
                      These are the things you owe.
                    </div>
                    {liabilityData.map((ele, index) => (
                      <div
                        className="liability-card mt-3 fw-500"
                        key={index}
                        onClick={() =>
                          handleEditAssetLiability(ele, "editLiability")
                        }
                      >
                        <InfoCard
                          amount={ele?.amount}
                          avatar={ele?.emoji}
                          name={ele?.name}
                          isEmoji={true}
                          isLiability={true}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="profile-dashboard mt-3 ms-0 add-liability-btn-first">
                    <Link to={`/NetWorth/details/${uuid}`} state={{ elementType: 'liability' }}>
                      <button
                        className="comn-btn add-liability fw-500"
                        // onClick={() => navigate(`/NetWorth/details/${uuid}`, { state: "moeiz" })}
                      >
                        Add liability
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {modals.addLiability && (
          <AddAssets
            modalTitle={"liability"}
            modalName={"addLiability"}
            modalOpen={modals.addLiability}
            closeModal={closeModal}
            fetchLiability={fetchLiability}
            uuid={uuid}
            accountData={accountData}
          />
        )}
        {modals.addAsset && (
          <AddAssets
            modalTitle={"asset"}
            modalName={"addAsset"}
            modalOpen={modals.addAsset}
            closeModal={closeModal}
            fetchAssets={fetchAssets}
            uuid={uuid}
            accountData={accountData}
          />
        )}
      </div>

      {modals.editAsset && (
        <EditLiabilityOrAsset
          modalTitle={"asset"}
          modalName={"editAsset"}
          modalOpen={modals.editAsset}
          closeModal={closeModal}
          fetchAssets={fetchAssets}
          fetchLiability={fetchLiability}
          modalData={DataForUpdating}
          uuid={uuid}
          accountData={accountData}
        />
      )}
      {modals.editLiability && (
        <EditLiabilityOrAsset
          modalTitle={"liability"}
          modalName={"editLiability"}
          modalOpen={modals.editLiability}
          closeModal={closeModal}
          fetchLiability={fetchLiability}
          modalData={DataForUpdating}
          uuid={uuid}
          accountData={accountData}
          fetchAssets={fetchAssets}
        />
      )}
    </>
  );
};
export default NetWorth;
