import React, { useState } from "react";
import MainSidebar from "../MainSidebar";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AssetLiabilityDetail from "./AssetLiabilityDetail";
import { ConnectedAccounts } from "../ConnectedAccountsCard";
import useAccount from "../../hooks/useAccount";
import weburls from "../Weburls/weburls";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import axios from "axios";
import PageTitle from "../PageTitle";
import UserImageInfoToggle from "../UserImageInfoToggle";
import SectionTitle from "../SectionTitle.jsx";

const NetWorthDetails = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { accountData, accountLoading } = useAccount();
  const { accounts, id, emoji, elementType, isConnected } =
    location.state || {};

  const [chosenEmoji, setChosenEmoji] = useState(emoji || "😁");
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(isConnected);
  const [selectedAccounts, setSelectedAccounts] = useState(accounts || []);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedAccountsTotal, setSelectedAccountsTotal] = useState();
  const [loading, setLoading] = useState(false);

  const handleToggleSwitch = (data) => {
    const { accountId } = data;
    setSelectedAccounts((prevAccounts) => {
      const newAccounts = prevAccounts.includes(accountId)
        ? prevAccounts.filter((id) => id !== accountId)
        : [...prevAccounts, accountId];

      const currentBalance = accountData
        .filter((acc) => newAccounts.includes(acc.accountId))
        .reduce((total, acc) => total + acc.value, 0);
      setSelectedAccountsTotal(currentBalance);
      return newAccounts;
    });
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowEmojiPicker(false);
  };

  const onSubmitHandler = (data) => {
    if (elementType === "asset") {
      addNewAsset(data);
    } else {
      addNewLiability(data);
    }
  };

  const handleOnSubmitForUpdating = (data) => {
    if (elementType === "editAsset") {
      updateAsset(data);
    } else updateLiability(data);
  };

  const addNewAsset = async (data) => {
    try {
      const payload = {
        userUuid: uuid,
        assetsName: data.assetsName,
        value: data.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.post(weburls.Add_Assets, payload);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/NetWorth/${uuid}`);
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error, "ppppp");
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  const addNewLiability = async (data) => {
    try {
      const payload = {
        userUuid: uuid,
        liabilityName: data.assetsName,
        value: data.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.post(weburls.Add_Liability, payload);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/NetWorth/${uuid}`);
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const updateAsset = async (data) => {
    try {
      const payload = {
        userUuid: uuid,
        assetsUuid: id,
        assetsName: data.assetsName,
        value: data.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.put(weburls.Update_Asset, payload);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/NetWorth/${uuid}`);
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  const updateLiability = async (data) => {
    try {
      const payload = {
        userUuid: uuid,
        liabilityUuid: id,
        liabilityName: data.assetsName,
        value: data.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.put(weburls.Update_Liability, payload);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        navigate(`/NetWorth/${uuid}`);
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const deletAssetOrLiability = async () => {
    try {
      const response = await axios.delete(
        `${
          elementType === "editAsset"
            ? weburls.Delete_Asset
            : weburls.Delete_Liability
        }${uuid}/${id}`
      );
      console.log(response);

      if (response?.data?.code === 1) {
        toast.success(`${response.data.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response.data.message}`);
      }
      navigate(`/NetWorth/${uuid}`);
    } catch (error) {
      toast.error("Somthing went wrong while deleting...");
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid">
        <div className="appmain mainsidebar main-wrapper">
          <MainSidebar uuid={uuid} />
          <div className="app-main__outer financial-goals-main">
            <div className="row">
              {elementType === "editAsset" ||
              elementType === "editLiability" ? (
                <PageTitle
                  title={`Edit your ${
                    elementType === "editAsset" ? "asset" : "liability"
                  }`}
                  description={`Edit in your ${
                    elementType === "editAsset" ? "asset" : "liability"
                  } details below.`}
                />
              ) : (
                <PageTitle
                  title={`${
                    elementType === "asset" ? "Add an asset" : "Add a liability"
                  }`}
                  description={`Enter in your ${elementType} details below.`}
                />
              )}
              <UserImageInfoToggle />
            </div>
            <div className="row mt-5">
              <AssetLiabilityDetail
                {...location.state}
                setIsLinkedToAccount={setIsLinkedToAccount}
                isLinkedToAccount={isLinkedToAccount}
                showEmojiPicker={showEmojiPicker}
                setShowEmojiPicker={setShowEmojiPicker}
                chosenEmoji={chosenEmoji}
                handleEmojiClick={handleEmojiClick}
                handleOnSubmitForUpdating={handleOnSubmitForUpdating}
                onSubmitHandler={onSubmitHandler}
                deletAssetOrLiability={deletAssetOrLiability}
                selectedAccountsTotal={selectedAccountsTotal}
              />

              {isLinkedToAccount && (
                <div className="col-xl-7">
                  <SectionTitle title={"Link to an account"} />
                  <div className="">
                    <div className="d-flex flex-wrap link-account-gap">
                      {accountData?.map((ele, index) => (
                        <ConnectedAccounts
                          key={index}
                          avatar={ele?.avatar}
                          accountName={ele?.accountName}
                          value={ele?.value}
                          accountId={ele?.accountId}
                          handleToggleSwitch={handleToggleSwitch}
                          id={ele?.accountId}
                          width={"49%"}
                          selectedAccount={selectedAccounts}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {loading ||
                (accountLoading && (
                  <OverlayContainer>
                    <div>
                      <Loading />
                    </div>
                  </OverlayContainer>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetWorthDetails;
